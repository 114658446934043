html {
  @apply antialiased font-normal font-sans text-gray bg-gray-dark leading-normal;
  text-rendering: optimizeLegibility;

  --color-theme-primary: theme(colors.teal.700);
  --color-theme-primary-light: theme(colors.teal.500);
  --color-theme-secondary: theme(colors.green.400);
  --color-theme-secondary-light: theme(colors.green.300);
}

body {
  @apply overflow-x-hidden;
}

html,
body,
#__next {
  @apply flex flex-col min-h-full;
}

body,
#__next {
  @apply flex-grow;
}

/* Use Tailwind's focus treatment instead of browser default */
a,
button,
input,
textarea,
select {
  @apply transition-colors duration-200;

  &:focus {
    @apply outline-none shadow-outline;
  }
}

.hide-scroll {
  -ms-overflow-style: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.rich-text {
  h2,
  h3,
  p,
  ol,
  ul,
  blockquote,
  .divider {
    margin-top: 1.1em;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    @apply text-2xl font-medium;
  }

  h3 {
    @apply text-xl font-medium;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  ul,
  ol {
    @apply pl-6;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  li {
    @apply my-2;
  }

  blockquote {
    @apply pl-4 py-2 italic;
  }

  .divider {
    @apply block w-full h-1;
  }
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}
