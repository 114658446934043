.gradient-horizontal {
  background: linear-gradient(
    to bottom,
    theme('colors.gray-dark'),
    color-mod(theme(colors.gray-dark) a(45%)),
    color-mod(theme(colors.gray-dark) a(25%)),
    color-mod(theme(colors.gray-dark) a(45%)),
    theme('colors.gray-dark')
  );
}

.gradient-circle {
  background: radial-gradient(
    color-mod(theme(colors.gray-dark) a(20%)),
    color-mod(theme(colors.gray-dark) a(40%)) 30%,
    theme('colors.gray-dark') 60%
  );
}
